/* eslint-disable import/no-anonymous-default-export */
// import {

// } from "../actions/action";
import {
  GET_PAYMENT_TRANSACTION_REQUEST,
  GET_PAYMENT_TRANSACTION_SUCCESS,
  GET_PAYMENT_TRANSACTION_FAILURE,
  CHECK_PAYMENT_STATUS_REQUEST,
  CHECK_PAYMENT_STATUS_SUCCESS,
  CHECK_PAYMENT_STATUS_FAILURE,
  SUBMIT_PAYMENT_REQUEST,
  SUBMIT_PAYMENT_SUCCESS,
  SUBMIT_PAYMENT_FAILURE,
} from "../actions/actions";
const INITIAL_STATE = {
  paymentTransactionInfo: null,
  isLoadingGetPaymentTransactionInfo: false,
  isLoadingCheckPaymentStatus: false,
  paymentStatusData: null,
  isLoadingSubmitPayment: false,
};

const GatewayReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_PAYMENT_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoadingGetPaymentTransactionInfo:
          action.payload.isLoadingGetPaymentTransactionInfo,
      };
    case GET_PAYMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoadingGetPaymentTransactionInfo:
          action.payload.isLoadingGetPaymentTransactionInfo,
        paymentTransactionInfo: action.payload.paymentTransactionInfo,
      };
    case GET_PAYMENT_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoadingGetPaymentTransactionInfo:
          action.payload.isLoadingGetPaymentTransactionInfo,
      };
    case CHECK_PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        isLoadingCheckPaymentStatus: action.payload.isLoadingCheckPaymentStatus,
      };
    case CHECK_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingCheckPaymentStatus: action.payload.isLoadingCheckPaymentStatus,
        paymentStatusData: action.payload.paymentStatusData,
      };
    case CHECK_PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        isLoadingCheckPaymentStatus: action.payload.isLoadingCheckPaymentStatus,
      };
    case SUBMIT_PAYMENT_REQUEST:
      return {
        ...state,
        isLoadingSubmitPayment: action.payload.isLoadingSubmitPayment,
      };
    case SUBMIT_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoadingSubmitPayment: action.payload.isLoadingSubmitPayment,
      };
    case SUBMIT_PAYMENT_FAILURE:
      return {
        ...state,
        isLoadingSubmitPayment: action.payload.isLoadingSubmitPayment,
      };
    default:
      return state;
  }
};

export default GatewayReducer;
