import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import SnackBarReducer from "../reducers/snackbar";
import UserReducer from "../reducers/user";
import GatewayReducer from "../reducers/gateway";
import PayModeReducer from "../reducers/paymode";
export const store = configureStore({
  reducer: {
    snackbar: SnackBarReducer,
    user: UserReducer,
    gateway: GatewayReducer,
    paymode: PayModeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
