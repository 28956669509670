import './App.css';
import { ThemeProvider } from "@mui/material/styles";
import "./styles/main.scss";
import theme from "./styles/theme";
import RoutesPath from "./Routes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RoutesPath />
    </ThemeProvider>
  );
}

export default App;
