export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";


export const POST_REGISTER_REQUEST = "POST_REGISTER_REQUEST";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_FAILURE = "POST_REGISTER_FAILURE";

export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE";

  //Merchant //Gateway
  export const GET_PAY_MODE_REQUEST = "GET_PAY_MODE_REQUEST";
  export const GET_PAY_MODE_SUCCESS = "GET_PAY_MODE_SUCCESS";
  export const GET_PAY_MODE_FAILURE = "GET_PAY_MODE_FAILURE";

  //Transaction Info //Gateway
  export const GET_PAYMENT_TRANSACTION_REQUEST = "GET_PAYMENT_TRANSACTION_REQUEST";
  export const GET_PAYMENT_TRANSACTION_SUCCESS = "GET_PAYMENT_TRANSACTION_SUCCESS";
  export const GET_PAYMENT_TRANSACTION_FAILURE = "GET_PAYMENT_TRANSACTION_FAILURE";

  //Check Payment Status //Gateway
  export const CHECK_PAYMENT_STATUS_REQUEST = "CHECK_PAYMENT_STATUS_REQUEST";
  export const CHECK_PAYMENT_STATUS_SUCCESS = "CHECK_PAYMENT_STATUS_SUCCESS";
  export const CHECK_PAYMENT_STATUS_FAILURE = "CHECK_PAYMENT_STATUS_FAILURE";

  //SUBMIT PAYMENT
  export const SUBMIT_PAYMENT_REQUEST = "SUBMIT_PAYMENT_REQUEST";
  export const SUBMIT_PAYMENT_SUCCESS = "SUBMIT_PAYMENT_SUCCESS";
  export const SUBMIT_PAYMENT_FAILURE = "SUBMIT_PAYMENT_FAILURE";