/* eslint-disable import/no-anonymous-default-export */

import {
    GET_PAY_MODE_FAILURE,
    GET_PAY_MODE_REQUEST,
    GET_PAY_MODE_SUCCESS,
  } from "../actions/actions";
  
  const INITIAL_STATE = {
    payModeData: null,
    isLoadingGetPayMode: false,
  };
  
  const PayModeReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case GET_PAY_MODE_REQUEST:
        return {
          ...state,
          isLoadingGetPayMode: action.payload.isLoadingGetPayMode,
        };
      case GET_PAY_MODE_SUCCESS:
        return {
          ...state,
          isLoadingGetPayMode: action.payload.isLoadingGetPayMode,
          payModeData: action.payload.payModeData,
        };
      case GET_PAY_MODE_FAILURE:
        return {
          ...state,
          isLoadingGetPayMode: action.payload.isLoadingGetPayMode,
        };
      default:
        return state;
    }
  };
  
  export default PayModeReducer;
  