/* eslint-disable import/no-anonymous-default-export */
// import {

// } from "../actions/action";
import {
    POST_LOGIN_FAILURE,
    POST_LOGIN_REQUEST,
    POST_LOGIN_SUCCESS,
    POST_REGISTER_FAILURE,
    POST_REGISTER_REQUEST,
    POST_REGISTER_SUCCESS,
  } from "../actions/actions";
  const INITIAL_STATE = {
    isLoadingLogin: false,
    // isLoadingRegister: false,
    // isLoadingGetUserInfo: false,
    // userInfo: null,
    // isLoadingUpdateUser: false,
  };
  
  const UserReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case POST_LOGIN_REQUEST:
        return {
          ...state,
          isLoadingLogin: action.payload.isLoadingLogin,
        };
    //   case POST_LOGIN_SUCCESS:
    //     return {
    //       ...state,
    //       isLoadingLogin: action.payload.isLoadingLogin,
    //     };
    //   case POST_LOGIN_FAILURE:
    //     return {
    //       ...state,
    //       isLoadingLogin: action.payload.isLoadingLogin,
    //     };
    //   case POST_REGISTER_REQUEST:
    //     return {
    //       ...state,
    //       isLoadingRegister: action.payload.isLoadingRegister,
    //     };
    //   case POST_REGISTER_SUCCESS:
    //     return {
    //       ...state,
    //       isLoadingRegister: action.payload.isLoadingRegister,
    //     };
    //   case POST_REGISTER_FAILURE:
    //     return {
    //       ...state,
    //       isLoadingRegister: action.payload.isLoadingRegister,
    //     };
    //   case GET_USER_INFO_REQUEST:
    //     return {
    //       ...state,
    //       isLoadingGetUserInfo: action.payload.isLoadingGetUserInfo,
    //     };
    //   case GET_USER_INFO_SUCCESS:
    //     return {
    //       ...state,
    //       isLoadingGetUserInfo: action.payload.isLoadingGetUserInfo,
    //       userInfo: action.payload.userInfo,
    //     };
    //   case GET_USER_INFO_FAILURE:
    //     return {
    //       ...state,
    //       isLoadingGetUserInfo: action.payload.isLoadingGetUserInfo,
    //     };
    //   case UPDATE_USER_INFO_REQUEST:
    //     return {
    //       ...state,
    //       isLoadingUpdateUser: action.payload.isLoadingUpdateUser,
    //     };
    //   case UPDATE_USER_INFO_SUCCESS:
    //     return {
    //       ...state,
    //       isLoadingUpdateUser: action.payload.isLoadingUpdateUser,
    //     };
    //   case UPDATE_USER_INFO_FAILURE:
    //     return {
    //       ...state,
    //       isLoadingUpdateUser: action.payload.isLoadingUpdateUser,
    //     };
      default:
        return state;
    }
  };
  
  export default UserReducer;
  