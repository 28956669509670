import { Route, Routes, Outlet } from "react-router-dom";
import CustomBackdrop from "./components/Backdrop";
import PrivateRoute from "./privateRoute";
import React, { Suspense, lazy } from "react";
const RoutesPath = () => {
  const HomePage = lazy(() => import("./pages/home"));
  const PaymentPage = lazy(() => import("./pages/payment"));
  const StatusPage = lazy(() => import("./pages/status"));
  const RedirectPage = lazy(() => import("./pages/redirect"));
  return (
    <Suspense fallback={<CustomBackdrop isLoading={true} />}>
      <Routes>
        <Route path="/" element={<Outlet />}>
          {/* <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} /> */}
          <Route path="/payment/:transactionId" element={<PaymentPage />} />
          <Route path="/payment/status" element={<StatusPage />} />
          <Route path="/payment/redirect" element={<RedirectPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutesPath;
