import {
    CLOSE_SNACKBAR,
    ENQUEUE_SNACKBAR,
    REMOVE_SNACKBAR,
  } from "../actions/actions";
  
  const INITIAL_STATE = {
    notifications: [] as any,
  };
  
  const SnackBarReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case ENQUEUE_SNACKBAR:
        return {
          ...state,
          notifications: [
            ...state.notifications,
            { key: action.key, ...action.notification },
          ],
        };
  
      case CLOSE_SNACKBAR:
        return {
          ...state,
          notifications: state.notifications.map((notification: any) =>
            (notification && notification.dismissAll) ||
            notification.key === action.key
              ? { ...notification, dismissed: true }
              : { ...notification }
          ),
        };
      case REMOVE_SNACKBAR:
        return {
          ...state,
          notifications: state.notifications.filter(
            (notification: any) => notification.key !== action.key
          ),
        };
      default:
        return state;
    }
  };
  
  export default SnackBarReducer;
  